.project-card {
  font-weight: 300;
}

.project-card h3 {
  font-weight: 300;
  font-size: 1.5rem;
}

.project-card a,
.project-card a:hover,
.project-card a:visited,
.project-card a:active {
  color: inherit;
  text-decoration: none;
}
a.github-link:hover {
  text-decoration: underline;
}

h2 {
  font-size: 1.5rem !important;
}
.icon-link {
  font-size: 1.25rem;
  color: #212529;
  font-weight: 300;
}

a.icon-link:hover {
  color: #7c8690;
}
